import isEqual from "lodash/isEqual";

export interface GridItem {
  id?: string;

  image?: {
    src?: string;
    width?: number;
    height?: number;
  };

  title?: string;
  subtitle?: string[];
  subtitle_label?: string;

  tag?: string;

  link?: string;

  category?: string;

  post?: PostItem;

  lazy?: boolean;
}

export const useGrid = () => {
  const localePath = useLocalePath();

  const splitItems = <T = any>(
    items: MaybeRef<T[]>,
    index: MaybeRef<number>
  ) => {
    const itemsBefore = ref<T[]>([]) as Ref<T[]>;
    const itemsAfter = ref<T[]>([]) as Ref<T[]>;

    watch(
      [items, index],
      () => {
        const newItemsBefore = [...unref(items).slice(0, unref(index))];
        const newItemsAfter = [...unref(items).slice(unref(index))];

        if (!isEqual(itemsBefore.value, newItemsBefore)) {
          itemsBefore.value = newItemsBefore;
        }

        if (!isEqual(itemsAfter.value, newItemsAfter)) {
          itemsAfter.value = [...unref(items).slice(unref(index))];
        }
      },
      { immediate: true }
    );

    return { itemsBefore, itemsAfter };
  };

  const { getPostTypeSlug } = usePostTypes();

  const postToGrid = (
    post: MaybeRef<PostItem>,
    overrideImage?: MaybeRef<ImageItem>,
    { index, lazyIndex }: { index?: number; lazyIndex?: number } = {}
  ): GridItem => {
    post = unref(post);
    overrideImage = unref(overrideImage);

    const { id, title, subtitle_label, slug, type, cover, tags } = post;

    const image = overrideImage
      ? {
          provider: "directus",
          src:
            overrideImage?.file?.id +
            (overrideImage?.file?.filename_download
              ? "/" + encodeURIComponent(overrideImage.file.filename_download)
              : ""),
          width: overrideImage?.file?.width,
          height: overrideImage?.file?.height,
          alt: overrideImage?.file?.description,
        }
      : {
          provider: "directus",
          src:
            cover?.file?.id +
            (cover?.file?.filename_download
              ? "/" + encodeURIComponent(cover.file.filename_download)
              : ""),
          width: cover?.file?.width,
          height: cover?.file?.height,
          alt: cover?.file?.description,
        };

    const typeSlug = type ? getPostTypeSlug(type) : undefined;

    const link = localePath({
      name: "type-slug",
      params: { type: typeSlug, slug },
    });

    const { getPostTypeTitleSingular } = usePostTypes();

    const category = type ? getPostTypeTitleSingular(type) || "Wpis" : "Wpis";

    const tag = tags?.reduce<PostTagItem | null>(
      (acc, tag) =>
        acc && (tag.sort || 0) < (acc.sort || 0) ? tag : !acc ? tag : acc,
      null
    )?.tags_id?.title;

    const subtitle =
      post.type &&
      [PostType.Inspiration, PostType.Contractor, PostType.Project].includes(
        post.type
      ) &&
      post.subtitle_type === "architects"
        ? [
            post.architect_name || undefined!,
            ...(post.architects?.map(
              (architect) => architect.name || undefined!
            ) || []),
          ].filter(Boolean)
        : [post.subtitle!].filter(Boolean);

    const lazy = lazyIndex
      ? index && index >= lazyIndex
        ? true
        : false
      : true;

    return {
      id,
      image,
      link,
      subtitle,
      subtitle_label,
      tag,
      title,
      category,
      post,
      lazy,
    };
  };

  return { splitItems, postToGrid };
};
