<script setup lang="ts">
const { currentPalette } = useTheme();

const linkUrl = atob("aHR0cHM6Ly9pbW9saS5kZXY=");
const linkText = atob("V3lrb25hbmllOiBpbW9saS5kZXY=");

const route = useRoute();
const isContained = computed(() => route.meta.footerContained);
</script>

<template>
  <footer class="app-footer contrast-fix" :class="[currentPalette.bg]">
    <div class="app-footer__top py-12">
      <div class="app-footer__container container">
        <div
          class="app-footer__wrapper flex items-center gap-6 justify-between"
          :class="{ 'lg:px-40': isContained }"
        >
          <div class="app-footer__side app-footer__side--start">
            <slot name="top-start"> </slot>
          </div>
          <div class="app-footer__side app-footer__side--end">
            <slot name="top-end">
              <AppFooterDecoration />
            </slot>
          </div>
        </div>
      </div>
    </div>
    <div class="app-footer__bottom py-12">
      <div class="app-footer__container container">
        <div
          class="app-footer__wrapper flex items-center gap-6 justify-between"
          :class="{ 'lg:px-40': isContained }"
        >
          <div class="app-footer__side app-footer__side--start">
            <slot name="bottom-start">
              <AppFooterMenu />
            </slot>
          </div>
          <div class="app-footer__side app-footer__side--end">
            <slot name="bottom-end">
              <AppFooterSocial />
            </slot>
          </div>
        </div>
      </div>
    </div>

    <a
      class="sr-only fixed bottom-0"
      :href="linkUrl"
      target="_blank"
      rel="noopener nofollow"
      tabindex="0"
    >
      {{ linkText }}
    </a>
  </footer>

  <div
    class="ios-peek fixed z-[-1] bottom-0 inset-x-0 w-full h-[50vh]"
    :class="[currentPalette.bg]"
  ></div>
</template>
