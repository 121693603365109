export interface FooterMenuItem extends Record<string, any> {
  title: string;
  to?: string;
  href?: string;
  class?: string;
  rel?: string;
}

export interface FooterSocialItem {
  title: string;
  link: string;
  icon: string;
}

export const useFooter = () => {
  const localePath = useLocalePath();

  const footerMemu = computed<FooterMenuItem[]>(() => [
    { title: "kontakt", to: localePath({ name: "contact" }) },
    {
      title: "polityka prywatności",
      to: localePath({ name: "legal-privacy" }),
      rel: "nofollow",
    },
    {
      title: "cookies",
      href: "#",
      class: "cky-banner-element",
      rel: "nofollow",
    },
    {
      title: "regulamin",
      to: localePath({ name: "legal-terms" }),
      rel: "nofollow",
    },
  ]);

  const socialMenu = computed<FooterSocialItem[]>(() => [
    {
      title: "Facebook",
      link: "https://www.facebook.com/nowoczesnaSTODOLA",
      icon: "ns:facebook",
    },
    {
      title: "Instagram",
      link: "https://www.instagram.com/nowoczesnastodola/",
      icon: "ns:instagram",
    },
    {
      title: "Pinterest",
      link: "https://pl.pinterest.com/nowoczesnaSTODOLA/",
      icon: "ns:pinterest",
    },
  ]);

  const { useItems } = useCms();
  const { readItems } = useItems();

  const getFooterContent = async (page: MaybeRef<PageType>) => {
    return await readItems("footers", {
      limit: 1,
      offset: 0,
      filter: { page: { _eq: unref(page) } },
    })
      .then((response: FooterContentItem[]) => response?.[0] || null)
      .catch(() => null);
  };

  return { footerMemu, socialMenu, getFooterContent };
};
