<script setup lang="ts">
const emit = defineEmits<{ close: [] }>();

const { openAuthDialog } = useAuth();
const { currentPalette } = useTheme();
const { isMobile } = useUserDevice();

const { getAuthViewImage } = useViewsSettings();

const viewImage = computed(() => getAuthViewImage(AuthView.Reset)?.id);

const onClose = () => {
  emit("close");
  openAuthDialog(AuthView.Login);
};
</script>

<template>
  <div class="app-auth-reset-success flex flex-col items-stretch">
    <div
      class="app-auth-reset-success__wrapper grow flex flex-col items-stretch"
    >
      <div class="grow basis-[24.5625rem] relative">
        <NuxtPicture
          v-if="isMobile"
          :src="viewImage || '/img/contact-success.jpeg'"
          :provider="viewImage ? 'directus' : undefined"
          class="app-auth-reset-success__image block w-full h-full object-cover absolute inset-0"
          :img-attrs="{
            class: 'block w-full h-full object-cover absolute inset-0',
            role: 'presentation',
          }"
          width="353"
          height="500"
          role="presentation"
          alt=""
          :class="[currentPalette.bg]"
          preload
        />
      </div>

      <div
        class="app-auth-reset-success__actions mt-12 flex flex-col items-stretch gap-6"
      >
        <AppBtn type="button" variant="solid" block @click="onClose">
          Przejdź do logowania
        </AppBtn>
      </div>
    </div>
  </div>
</template>
